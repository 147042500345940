/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NomenclatureStatus = "anonymous_authorship" | "art_13_nomen_oblitum" | "as_emended" | "available" | "based_on_a_suppressed_name" | "based_on_homonym" | "before_1758" | "collective_group" | "conditional" | "fully_suppressed" | "hybrid_as_such" | "hybrid_name" | "hypothetical_concept" | "inconsistently_binominal" | "incorrect_original_spelling" | "incorrect_subsequent_spelling" | "informal" | "infrasubspecific" | "ites_name" | "justified_emendation" | "mandatory_change" | "misidentification" | "multiple_words" | "name_combination" | "no_type_specified" | "nomen_novum" | "nomen_nudum" | "not_based_on_a_generic_name" | "not_explicitly_new" | "not_intended_as_a_scientific_name" | "not_latin_alphabet" | "not_nominative_singular" | "not_published_with_a_generic_name" | "not_used_as_genus_plural" | "not_used_as_valid" | "partially_suppressed" | "placed_on_index" | "preoccupied" | "rejected_by_fiat" | "reranking" | "subsequent_usage" | "teratological" | "type_not_treated_as_valid" | "unjustified_emendation" | "unlisted" | "unpublished" | "unpublished_electronic" | "unpublished_pending" | "unpublished_supplement" | "unpublished_thesis" | "variant" | "variety_or_form" | "work_of_extant" | "zoological_formula" | "%future added value";
export type PersonType = "alias" | "checked" | "deleted" | "hard_redirect" | "soft_redirect" | "unchecked" | "%future added value";
export type Status = "composite" | "dubious" | "hybrid" | "nomen_dubium" | "redirect" | "removed" | "species_inquirenda" | "spurious" | "synonym" | "unavailable" | "valid" | "%future added value";
export type ModelLink_model = {
    readonly oid: number;
    readonly __typename: string;
    readonly locationRegion?: {
        readonly regionName: string;
    };
    readonly minPeriod?: {
        readonly periodName: string;
    } | null;
    readonly maxPeriod?: {
        readonly periodName: string;
    } | null;
    readonly stratigraphicUnit?: {
        readonly periodName: string;
    } | null;
    readonly city?: string | null;
    readonly status?: Status;
    readonly nomenclatureStatus?: NomenclatureStatus;
    readonly correctedOriginalName?: string | null;
    readonly taxon?: {
        readonly validName: string;
        readonly " $fragmentRefs": FragmentRefs<"Title_model">;
    };
    readonly birth?: string | null;
    readonly death?: string | null;
    readonly bio?: string | null;
    readonly personType?: PersonType;
    readonly source?: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLinkNoExtra_model">;
    } | null;
    readonly date?: number;
    readonly page?: string | null;
    readonly text?: string;
    readonly article?: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLinkNoExtra_model">;
    };
    readonly mappedName?: {
        readonly correctedOriginalName: string | null;
        readonly taxon: {
            readonly validName: string;
            readonly " $fragmentRefs": FragmentRefs<"ModelLinkNoExtra_model">;
        };
        readonly " $fragmentRefs": FragmentRefs<"ModelLinkNoExtra_model">;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"ModelLinkNoExtra_model">;
    readonly " $refType": "ModelLink_model";
};
export type ModelLink_model$data = ModelLink_model;
export type ModelLink_model$key = {
    readonly " $data"?: ModelLink_model$data;
    readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ModelLinkNoExtra_model"
},
v1 = [
  {
    "alias": "periodName",
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correctedOriginalName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "validName",
  "storageKey": null
},
v4 = [
  (v0/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "page",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModelLink_model",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "locationRegion",
          "args": null,
          "concreteType": "Region",
          "kind": "LinkedField",
          "name": "region",
          "plural": false,
          "selections": [
            {
              "alias": "regionName",
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Period",
          "kind": "LinkedField",
          "name": "minPeriod",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Period",
          "kind": "LinkedField",
          "name": "maxPeriod",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StratigraphicUnit",
          "kind": "LinkedField",
          "name": "stratigraphicUnit",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "type": "Location",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        }
      ],
      "type": "Collection",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nomenclatureStatus",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Taxon",
          "kind": "LinkedField",
          "name": "taxon",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Title_model"
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Name",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "birth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "death",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bio",
          "storageKey": null
        },
        {
          "alias": "personType",
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "type": "Person",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Article",
          "kind": "LinkedField",
          "name": "source",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/)
      ],
      "type": "NameComment",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v5/*: any*/),
        (v7/*: any*/)
      ],
      "type": "ArticleComment",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Article",
          "kind": "LinkedField",
          "name": "article",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Name",
          "kind": "LinkedField",
          "name": "mappedName",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Taxon",
              "kind": "LinkedField",
              "name": "taxon",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "ClassificationEntry",
      "abstractKey": null
    }
  ],
  "type": "Model",
  "abstractKey": "__isModel"
};
})();
(node as any).hash = 'df02b525c2b1d63071cef1c9ae7dabfc';
export default node;
